$primary-btn-text: #ffff;

.flowy-landing-page {
  .invocation-count {
    margin-right: 40px;
    font-size: 16px;
  }
  .white-text-button{
    color: $primary-btn-text !important;
  }
  .flowy-content {
    display: block;
    margin-left: auto;
    margin-right: auto;

    .flowy-image {
      height: 25rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .flowy-landing-text {
      text-align: center;
      margin-bottom: 3.5rem;
      font-weight: bold;
    }
    .bot-create-form {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;
    }

    .bot-create-text {
      text-align: center;
      margin-bottom: 3.5rem;
      font-weight: bold;
    }

    .white-text-button {
      color: $primary-btn-text !important;
      font-size: 1.2rem;
    }
  }
  .text-center {
    display: flex;
    font-size: 1.2rem;
    padding: 0 25%;
  }
}
