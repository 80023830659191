.no-helpdesk-account-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
  
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    .error {
      color: red;
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }
  
    button {
      padding: 8px 15px;
      font-size: 18px;
    }
  }
  