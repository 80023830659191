.wizard-header {
  .step-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004b78!important;
    border: 1px solid;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 2rem;
    padding: 0;
  }

  .line-container {
    width: 40%;
    height: 100%;
  }
  .horizontal_line{
    border: 0.1rem solid  #004b78;
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.5rem;

  }
}
