@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts//Poppins/Poppins-Regular.ttf') format('woff2'),
       url('./assets/fonts//Poppins/Poppins-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100%;
  font-family: "Poppins", sans-serif !important;
  font-size: calc(100vw * 0.00833)!important;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
  
}

.svg-icon{
  color:var(--primary-color);
  fill: currentColor;
}