// Import the Bootstrap variables
@import "~bootstrap/scss/bootstrap";

// Define your custom primary color
$primary: #00aeff; // Replace with your desired primary color
$danger: #dc3545;
$secondary:#8A92A6;
$primary-link:#0096FF;
$btn-color: #fff;

// Override Bootstrap variables with your custom values
$theme-colors: (
  "primary": $primary,
  "danger":$danger,
  "secondary": $secondary,
  "primary-link": $primary-link
  // Add other Bootstrap colors as needed
);

// Customize Bootstrap button text color
$btn-primary-color: #fff; // Set the button text color to white

// Override Bootstrap's primary button text color
.btn-primary {
  color: $btn-primary-color !important;;
}

// Import Bootstrap to generate the custom theme
@import "~bootstrap/scss/bootstrap";