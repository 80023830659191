.sh-icon {
    width: 1em;
    // fill: currentColor;
    margin-bottom: 0.2rem;
    &.icon-xs {
        width: 1em;
        font-size: 0.75em;
    }
    &.icon-sm {
        width: 1em;
        font-size: 0.875em;
    }
    &.icon-lg {
        width: 1em;
        font-size: 1.3333333333em;
    }
    &.icon-2x {
        font-size: 2em;
    }
    &.icon-3x {
        font-size: 3em;
    }
    &.icon-4x {
        font-size: 4em;
    }
}
